import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PhoneIcon } from "../../assets/images/ManagementSaleOrg/phone.svg";
import { ReactComponent as WhatsIcon } from "../../assets/images/ManagementSaleOrg/whats.svg";

import { useDispatch } from "react-redux";
import { logCallOrWhatsapp } from "../../data/slices/callMsgGoodsCounterSlice";

const MsgCallButtonsGoods = ({
  type,
  whtsappNumber,
  phoneNumber,
  userPropertyRefID,
  id,
  name,
  className = "",
  offerType,
}) => {
  console.log(offerType);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const defaultMessage = `${t("properties.whatsapp_msg_pre")} ${
    userPropertyRefID ? userPropertyRefID : "---"
  }\n${t("properties.whatsapp_msg_aft")} ${name}`;

  const encodedMessage = encodeURIComponent(defaultMessage);

  const handleButtonClick = () => {
    const from = type === "whatsapp" ? "whatsapp" : "call";
    dispatch(logCallOrWhatsapp({ from, id, offerType }));
  };

  const renderButton = () => {
    if (type === "whatsapp") {
      return (
        <a
          href={`https://api.whatsapp.com/send?phone=${whtsappNumber}&text=${encodedMessage}`}
          className={className}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleButtonClick}
        >
          <WhatsIcon />
        </a>
      );
    } else if (type === "call") {
      return (
        <a
          href={`tel:${phoneNumber}`}
          className={className}
          onClick={handleButtonClick}
        >
          <PhoneIcon />
        </a>
      );
    }
    return null;
  };

  return <div>{renderButton()}</div>;
};

export default MsgCallButtonsGoods;
