import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

// Initial state for the slice
const initialState = {
  loading: false,
  error: null,
};

// Create async thunk for the API call
export const logCallOrWhatsapp = createAsyncThunk(
  "callMsgCounter/logCallOrWhatsapp",
  async ({ from, id, offerType }) => {
    console.log({
      from,
      id,
      offerType,
    });
    try {
      // Make the API call using axios
      const response = await axiosInstance.get("/goods/whatsapp_call_count", {
        params: {
          from,
          id,
          offer_type: offerType,
        },
      });
      return response.data; // Return the response data
    } catch (error) {
      // Throw an error if the request fails
      throw new Error(
        error.response?.data?.message ||
          "Failed to log the call or WhatsApp action"
      );
    }
  }
);

// Create the slice
const callMsgGoodsCounterSlice = createSlice({
  name: "callMsgCounter",
  initialState,
  reducers: {}, // No synchronous reducers needed for this example
  extraReducers: (builder) => {
    builder
      .addCase(logCallOrWhatsapp.pending, (state) => {
        state.loading = true; // Set loading to true when the request is pending
        state.error = null; // Reset error on new request
      })
      .addCase(logCallOrWhatsapp.fulfilled, (state) => {
        state.loading = false; // Set loading to false on successful request
      })
      .addCase(logCallOrWhatsapp.rejected, (state, action) => {
        state.loading = false; // Set loading to false on request failure
        state.error = action.error.message; // Store the error message
      });
  },
});

// Export the reducer to be used in the store
export default callMsgGoodsCounterSlice.reducer;
