import React from "react";
import logo from "../../../../assets/images/ManagementSaleOrg/logo.png";
import phone from "../../../../assets/images/ManagementSaleOrg/phone.svg";
import whts from "../../../../assets/images/ManagementSaleOrg/whats.svg";
import "./_CallSection.scss";
import MsgCallButtonsGoods from "../../../../components/MsgCallButtons/MsgCallButtonsGoods";
function CallSection({ property }) {
  return (
    <div>
      <div className="logo_section">
        {property?.user_details?.selling_agent?.image && (
          <img
            src={property?.user_details?.selling_agent?.image}
            className="logo_img"
            alt=""
          />
        )}
        <div className="call">
          <MsgCallButtonsGoods
            type="whatsapp"
            whtsappNumber={property.whatsapp}
            id={property.id}
            userPropertyRefID={property.user_goods_ref_id}
            name={property.name}
            className="btn_call"
            offerType={property.offer_type}
          />
          <MsgCallButtonsGoods
            type="call"
            phoneNumber={property.whatsapp}
            className="btn_call"
            id={property.id}
            offerType={property.offer_type}
          />
        </div>
      </div>
    </div>
  );
}

export default CallSection;
